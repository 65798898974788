import { createBrowserRouter, createRoutesFromElements, Route, Navigate, Outlet } from "react-router-dom";

// Pages
import LoginPage from "@pages/LoginPage";
import ResetPasswordPage from "@pages/ResetPasswordPage";
import SetPasswordPage from "@pages/SetPasswordPage";
import NotFoundPage from "@pages/NotFoundPage";
import NotAllowedPage from '../pages/NotAllowedPage';
import DashboardPage from '@pages/DashboardPage';
import SettingsPage from '@pages/SettingsPage';
import AppLayoutPage from "@pages/AppLayoutPage";
import LocationsPage from "@pages/LocationPage";
import SearchPage from '@pages/SearchPage';
import AdminPage from '@pages/AdminPage';
import UnderConstructionPage from "@pages/UnderConstructionPage";
import AboutSiteRiseApp from "@pages/AboutSiteRiseApp";
import { TestErrorPage } from "@devtesting/components/pages/TestErrorPage";
import MarkupPage from '@pages/MarkupPage';

import LocationCreate from '../components/Locations/LocationCreate';
import LocationUpdate from '../components/Locations/LocationUpdate';
import SitesCreate from '../components/Locations/Sites/SitesCreate';
import CustomFieldGroupUpdate from '../components/Locations/CustomFields/CustomFieldGroupUpdate';
import { Settings } from '../components/Settings/Settings';
import ApiAccess from '../components/Settings/ApiAccess';
import { handleError } from './Util';
import { LocationNameLabel } from '../components/Locations/LocationNameLabel';
import { SiteTabs, SitesTabsMap } from '../components/Locations/Sites/SiteTabs';
import { SiteNameLabel } from '../components/Locations/Sites/SiteNameLabel';
import AddProject from '../components/Sites/Projects/AddProject';
import { ProjectNameLabel } from '../components/Locations/Sites/Projects/ProjectNameLabel';
import { ProjectTabs, ProjectsTabsMap } from '../components/Locations/Sites/Projects/ProjectTabs';
import { Dashboard } from '@components/Dashboard/Dashboard';
import { LocationTabs, LocationsTabsMap } from '@components/Locations/Tabs/LocationTabs';
import { SearchPageBodyContent } from '@components/Search/SearchPageBodyContent';
import { SitesUpdate } from '@components/Locations/Sites/SiteUpdate';
import { AdminCrumbLabel } from '@components/Admin/AdminCrumbLabel';
import { UserNameCrumbLabel } from '@components/Admin/UserNameCrumbLabel';
import { RouteBaseProjectTabs } from '@components/Locations/Sites/Projects/RouteBaseProjectTabs';
import { UserListTab } from '@components/Admin/UserListTab';
import { UserDetailTab } from '@components/Admin/UserDetailTab';
import { UsersCrumbLabel } from '@components/Admin/UsersCrumbLabel';

import { RfiCreate } from '@components/Locations/Sites/Projects/RfiTab/create/RfiCreate';
import { RfiList } from '@components/Locations/Sites/Projects/RfiTab/list/RfiList';
import { RfiView } from '@components/Locations/Sites/Projects/RfiTab/RfiView';
import { ExecutiveReport } from '../pages/ExecutiveReport';
import { ChangeOrdersList } from '@components/Locations/Sites/Projects/ChangeOrdersTab/list/ChangeOrdersList';
import { ChangeOrderCreate } from '@components/Locations/Sites/Projects/ChangeOrdersTab/ChangeOrderCreate';
import { ChangeOrderView } from '@components/Locations/Sites/Projects/ChangeOrdersTab/ChangeOrderView';
import { ChangeOrderPublish } from '@components/Locations/Sites/Projects/ChangeOrdersTab/ChangeOrderPublish';
import { ChangeOrderCostsUpdate } from '@components/Locations/Sites/Projects/ChangeOrdersTab/CostDetail/ChangeOrderCostsUpdate';
import { ChangeOrderCostsCreate } from '@components/Locations/Sites/Projects/ChangeOrdersTab/CostDetail/ChangeOrderCostsCreate';
import { RouteBaseSitesTabs } from '@components/Locations/Sites/RouteBaseSiteTabs';
import { GroupsTab } from '@components/Admin/GroupsTab';
import { GroupsCrumbLabel } from '@components/Admin/GroupsCrumbLabel';
import { GroupNameCrumbLabel } from '@components/Admin/GroupNameCrumbLabel';
import { GroupDetailTab } from '@components/Admin/GroupDetailTab';
import { FilePreviewUtilsTab } from '@components/Admin/FilePreviewUtilsTab';
import { FilePreviewGenerationCrumbLabel } from '@components/Admin/FilePreviewGenerationCrumbLabel';
import { BulkUploaderCrumbLabel } from '@components/Admin/BulkUploaderCrumbLabel';
import { BulkUploaderTab } from '@components/Admin/BulkUploaderTab';
import { PermissionsCrumbLabel } from '@components/Admin/PermissionsCrumbLabel';
import { PermissionsTab } from '@components/Admin/PermissionsTab';
import { PunchListItemDetailsView } from '@components/Locations/Sites/Projects/PunchListTab/PunchListItemDetailsView/PunchListItemDetailsView';
import { PunchListTab } from '@components/Locations/Sites/Projects/PunchListTab/PunchListTab';
import { UploadFloorPlanView } from '@components/Locations/Sites/Projects/PunchListTab/UploadFloorPlanView/UploadFloorPlanView';
import { SelectPagesView } from '@components/Locations/Sites/Projects/PunchListTab/UploadFloorPlanView/SelectPagesView';
import { ModuleConfigurationTab } from '@components/Admin/ModuleConfigurationTab';
import { t } from 'i18next';
import { Overview } from '@components/Admin/Overview';
import { AccessAndPermissionsView } from '@components/Admin/AccessAndPermissions/AccessAndPermissionsView';
import { ProjectModuleConfig } from '@components/Admin/ProjectModuleConfig';
import { ApplicationConfigurationView } from '@components/Admin/ApplicationConfiguration/ApplicationConfigurationView';
import { CostManagementConfig } from '@components/Admin/ApplicationConfiguration/CostManagementConfig';
import { SiteModuleConfig } from '@components/Admin/SiteModuleConfig';
import { AccessAndPermissionsCrumbLabel } from '@components/Admin/AccessAndPermissions/AccessAndPermissionsCrumbLabel';
import { ApplicationConfigurationCrumbLabel } from '@components/Admin/ApplicationConfiguration/ApplicationConfigurationCrumbLabel';
import { ModuleConfigurationCrumbLabel } from '@components/Admin/ModuleConfiguration/ModuleConfigurationCrumbLabel';
import { CostManagementConfigCrumbLabel } from '@components/Admin/ApplicationConfiguration/CostManagementConfigCrumbLabel';
import { OtherSettingsConfig } from '@components/Admin/ApplicationConfiguration/OtherSettingsConfig';
import { DeliverableListTab } from '@components/Admin/ModuleConfiguration/DeliverableListTab';
import { ProjectDeliverablesTab } from '@components/Admin/ModuleConfiguration/ProjectDeliverablesTab';
import { TemplatesListTab } from '@components/Admin/ModuleConfiguration/TemplatesListTab';
import { TemplateEdit } from '@components/Admin/ModuleConfiguration/TemplateEdit/TemplateEdit';
import { RevealBIPage } from '@pages/RevealBIPage';
import { LocationsContainer } from '@components/Locations/LocationsVisualization/LocationsContainer';
import { BudgetSubtabsEnum } from '@components/Locations/Sites/Projects/BudgetTab/BudgetSubTabs';
import { BudgetTabBaseRoute } from '@components/Locations/Sites/Projects/BudgetTab/BudgetTabBaseRoute';
import { Markup } from '@components/Markup/Markup';

// Company Routes
import { Companies } from '@components/Companies/Companies';
import CompaniesPage from '@pages/CompaniesPage';
import CompanyDetails from '@components/Companies/CompanyDetails/CompanyDetails';
import { CompaniesNameLabel } from '@components/Companies/CompanyNameLabel';
import { NavigationMobilePhotoAlbum } from '@components/PhotoAlbum/NavigationMobilePhotoAlbum';
import { RouteBaseLocationsTabs } from '@components/Locations/Tabs/RouteBaseLocationTabs';
import { CostGroupsConfig } from '@components/Admin/ApplicationConfiguration/CostGroupsConfig';
import { IntegrationTab } from '@components/Admin/ApplicationConfiguration/IntegrationTab';
import { RFITab } from '@components/Admin/ModuleConfiguration/RFITab';
import { BudgetTab } from '@components/Admin/ModuleConfiguration/BudgetTab';
import { BudgetTemplateListTab } from '@components/Admin/ModuleConfiguration/BudgetTemplateListTab';
import { BudgetTemplateEdit } from '@components/Admin/ModuleConfiguration/BudgetTemplateEdit/BudgetTemplateEdit';
import { ApprovalRouting } from '@components/Admin/ModuleConfiguration/ApprovalRouting';
import { ChangeOrderTab } from "@components/Admin/ModuleConfiguration/ChangeOrderTab";
import { CustomFieldsTab } from "@components/Admin/ApplicationConfiguration/CustomFieldsTab";
import { AvailableCurrenciesView } from "@components/Admin/ApplicationConfiguration/AvailableCurrenciesView";
import { PunchListAdminTab } from "@components/Admin/ModuleConfiguration/PunchListAdminTab";
import { GoodDataPage } from "@pages/GoodDataPage";

export const RouteObjectList = createRoutesFromElements(
  <>
    <Route path="/login" element={<LoginPage />} {...handleError} />

    <Route path="account/password-reset" element={<ResetPasswordPage />} {...handleError} />
    <Route path="account/set-password" element={<SetPasswordPage />} {...handleError} />

    <Route path="/" element={<AppLayoutPage />} {...handleError}>
      <Route path="" element={<Navigate to="/location" />} {...handleError} />

      <Route path="dashboard" element={<DashboardPage />} {...handleError}>
        <Route path="" element={<Dashboard />} />
      </Route>

      <Route path="companies" element={<CompaniesPage />} handle={{ crumb: () => <span>{t('generic.companies')}</span> }} {...handleError}>
        <Route path="" element={<Companies />} handle={{ crumb: () => <span>{t('generic.companies')}</span> }} />
        <Route path=":id" handle={{ crumb: () => <CompaniesNameLabel /> }}>
          <Route path="" handle={{ crumb: () => <CompaniesNameLabel /> }} element={<CompanyDetails />}></Route>
        </Route>
      </Route>

      <Route path="search" element={<SearchPage />} {...handleError}>
        <Route path="" element={<SearchPageBodyContent />} />
      </Route>

      <Route path="location" element={<LocationsPage />} handle={{ crumb: () => <span>{t('generic.locations')}</span> }} {...handleError}>
        <Route path="" element={<LocationsContainer />} handle={{ crumb: () => <span>{t('generic.locations')}</span> }} />
        <Route path="new" element={<LocationCreate />} />
        <Route path=":id" handle={{ crumb: () => <LocationNameLabel /> }}>
          <Route path="" element={<RouteBaseLocationsTabs />}>
            <Route path="" element={<LocationTabs tab={LocationsTabsMap.OVERVIEW} />}>
              <Route path="edit" element={<LocationUpdate />} />
              <Route path="create-site" element={<SitesCreate />} />
              <Route path="custom-field-group/:customFieldGroupId" element={<CustomFieldGroupUpdate />} />
            </Route>

            {/* FUTURE - Activate for Locations Photo Album */}
            {/* <Route path="photos" element={<LocationTabs tab={LocationsTabsMap.PHOTO_ALBUM} />} handle={{ mobile: () => <NavigationMobilePhotoAlbum /> }} /> */}
          </Route>
          <Route path="sites/:siteId" handle={{ crumb: () => <SiteNameLabel /> }}>
            <Route path="" element={<RouteBaseSitesTabs />}>
              <Route path="" element={<SiteTabs tab={SitesTabsMap.OVERVIEW} />}>
                <Route path="add-project" element={<AddProject />} />
                <Route path="edit" element={<SitesUpdate />} />
              </Route>
              <Route
                path="photos"
                element={<SiteTabs tab={SitesTabsMap.PHOTOS} />}
                handle={{ mobile: () => <NavigationMobilePhotoAlbum /> }}
              />
            </Route>

            <Route path="project/:projectId" element={<RouteBaseProjectTabs />} handle={{ crumb: () => <ProjectNameLabel /> }}>
              <Route path="" element={<ProjectTabs tab={ProjectsTabsMap.OVERVIEW} />} />
              <Route path="overview" element={<ProjectTabs tab={ProjectsTabsMap.OVERVIEW} />} />
              <Route path="settings" element={<ProjectTabs tab={ProjectsTabsMap.SETTINGS} />} />

              <Route path="punch-list" element={<ProjectTabs tab={ProjectsTabsMap.PUNCHLIST} />}>
                <Route path="" index={true} element={<PunchListTab />} />
                <Route path="page/:pageId" element={<PunchListTab />} />
                <Route path="page/:pageId/punch-list-item/:punchListItem" element={<PunchListItemDetailsView />} />
                <Route path="upload-floor-plan" element={<UploadFloorPlanView />} />
                <Route path="process/:generationId/select-pages" element={<SelectPagesView />} />
              </Route>

              <Route path="ox-blue" element={<ProjectTabs tab={ProjectsTabsMap.OX_BLUE} />} />

              <Route path="deliverables" element={<ProjectTabs tab={ProjectsTabsMap.DELIVERABLE} />} />
              <Route path="deliverables/:RouteParamDeliverableId" element={<ProjectTabs tab={ProjectsTabsMap.DELIVERABLE} />} />

              <Route path="schedule" element={<ProjectTabs tab={ProjectsTabsMap.SCHEDULE} />} />

              <Route
                path="photos"
                element={<ProjectTabs tab={ProjectsTabsMap.PHOTO_ALBUM} />}
                handle={{ mobile: () => <NavigationMobilePhotoAlbum /> }}
              />

              <Route path="rfi" element={<ProjectTabs tab={ProjectsTabsMap.RFI} />}>
                <Route path="" element={<RfiList />} />
                <Route path="new" element={<RfiCreate />} />
                <Route path=":rfiId" element={<RfiView />} />
              </Route>

              <Route path="budget" element={<ProjectTabs tab={ProjectsTabsMap.BUDGET} />}>
                <Route path="" element={<BudgetTabBaseRoute tab={BudgetSubtabsEnum.DETAIL} />} />
                <Route path="summary" element={<BudgetTabBaseRoute tab={BudgetSubtabsEnum.SUMMARY} />} />
                <Route path="detail" element={<BudgetTabBaseRoute tab={BudgetSubtabsEnum.DETAIL} />} />
              </Route>

              <Route path="change-orders" element={<ProjectTabs tab={ProjectsTabsMap.CHANGE_ORDERS} />}>
                <Route path="" element={<ChangeOrdersList />} />
                <Route path="new" element={<ChangeOrderCreate />} />
                <Route path=":coId" element={<ChangeOrderView />} />
                <Route path=":coId/publish" element={<ChangeOrderPublish />} />
                <Route path=":coId/add-costs-detail" element={<ChangeOrderCostsCreate />} />
                <Route path=":coId/update-costs-detail" element={<ChangeOrderCostsUpdate />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="settings" element={<SettingsPage />} {...handleError}>
        <Route path="" element={<Settings />} />
        <Route path="api-access" element={<ApiAccess />}>
          <Route path="*" element={<ApiAccess />} />
        </Route>
      </Route>

      <Route path="markup" element={<MarkupPage />} {...handleError}>
        <Route path="" element={<Markup />} />
      </Route>

      <Route
        path="admin"
        element={<AdminPage />}
        {...handleError}
        handle={{ crumb: () => <AdminCrumbLabel />, crumbNavigateTo: () => '/admin' }}
      >
        <Route path="" index={true} element={<Overview />} handle={{ crumb: (data: any) => <span>{t('admin.overview')}</span> }} />
        <Route path="access-and-permissions" handle={{ crumb: (data: any) => <AccessAndPermissionsCrumbLabel info={data} /> }}>
          <Route index={true} element={<AccessAndPermissionsView />} />
          <Route path="users" element={<Outlet />} handle={{ crumb: (data: any) => <UsersCrumbLabel info={data} /> }}>
            <Route index={true} element={<UserListTab />} />
            <Route path=":userLogin" element={<UserDetailTab />} handle={{ crumb: (data: any) => <UserNameCrumbLabel /> }} />
          </Route>
          <Route path="groups" element={<Outlet />} handle={{ crumb: (data: any) => <GroupsCrumbLabel info={data} /> }}>
            <Route index={true} element={<GroupsTab />} />
            <Route path=":groupId" element={<GroupDetailTab />} handle={{ crumb: (data: any) => <GroupNameCrumbLabel /> }} />
          </Route>
          <Route path="permissions" element={<Outlet />} handle={{ crumb: (data: any) => <PermissionsCrumbLabel /> }}>
            <Route index={true} element={<PermissionsTab />} />
          </Route>
        </Route>
        <Route
          path="file-preview-utils"
          element={<Outlet />}
          handle={{ crumb: (data: any) => <FilePreviewGenerationCrumbLabel info={data} /> }}
        >
          <Route index={true} element={<FilePreviewUtilsTab />} />
        </Route>
        <Route
          path="bulk-uploader"
          element={<Outlet />}
          handle={{ crumb: (data: any) => <BulkUploaderCrumbLabel info={data} /> }}
        >
          <Route index={true} element={<BulkUploaderTab />} />
        </Route>
        <Route path="module-configuration" handle={{ crumb: (data: any) => <ModuleConfigurationCrumbLabel /> }}>
          <Route index={true} element={<ModuleConfigurationTab />} />
          <Route path="sites" element={<SiteModuleConfig />} handle={{ crumb: (data: any) => <span>Sites</span> }} />
          <Route path="projects" element={<ProjectModuleConfig />} handle={{ crumb: (data: any) => <span>Projects</span> }} />
          <Route path="project-deliverables" handle={{ crumb: (data: any) => <span>Project Deliverables</span> }}>
            <Route index={true} element={<ProjectDeliverablesTab />} />
            <Route path="deliverables" element={<DeliverableListTab />} handle={{ crumb: (data: any) => <span>Deliverables</span> }} />
            <Route path="templates" handle={{ crumb: (data: any) => <span>Templates</span> }}>
              <Route index={true} element={<TemplatesListTab />} />
              <Route path=":templateId" element={<TemplateEdit />} />
            </Route>
          </Route>
          <Route path="rfi" handle={{ crumb: (data: any) => <span>{t('admin.rfi')}</span> }}>
            <Route index={true} element={<RFITab />} />
          </Route>
          <Route path="change-order" handle={{ crumb: (data: any) => <span>{t('admin.changeOrder')}</span> }}>
            <Route index={true} element={<ChangeOrderTab />} />
          </Route>
          <Route path="budget" handle={{ crumb: (data: any) => <span>{t('budget.budget')}</span> }}>
            <Route index={true} element={<BudgetTab />} />
            <Route path="templates" handle={{ crumb: (data: any) => <span>{t('admin.templateList')}</span> }}>
              <Route index={true} element={<BudgetTemplateListTab />} />
              <Route path=":templateId" element={<BudgetTemplateEdit />} />
            </Route>
            <Route
              path="approval-routing"
              element={<ApprovalRouting />}
              handle={{ crumb: (data: any) => <span>{t('admin.approvalRouting')}</span> }}
            />
          </Route>
          <Route path="punch-list" handle={{ crumb: (data: any) => <span>{t('punchList.punchList')}</span> }}>
            <Route index={true} element={<PunchListAdminTab />} />
          </Route>
        </Route>
        <Route path="application-configuration" handle={{ crumb: (data: any) => <ApplicationConfigurationCrumbLabel /> }}>
          <Route index={true} element={<ApplicationConfigurationView />} />
          <Route path="cost-management" handle={{ crumb: (data: any) => <CostManagementConfigCrumbLabel /> }}>
            <Route index={true} element={<CostManagementConfig />} />
            <Route path="cost-groups" element={<CostGroupsConfig />} handle={{ crumb: (data: any) => <span>Cost Groups</span> }}></Route>
            <Route path="available-currencies" element={<AvailableCurrenciesView />} handle={{ crumb: (data: any) => <span>{t("admin.availableCurrencies")}</span> }}></Route>
          </Route>
          <Route path="other-settings" element={<OtherSettingsConfig />} handle={{ crumb: (data: any) => <span>Other Settings</span> }} />
          <Route path="integration" element={<IntegrationTab />} handle={{ crumb: (data: any) => <span>Integration</span> }} />
          <Route path="custom-fields" element={<CustomFieldsTab />} handle={{ crumb: (data: any) => <span>{t("admin.customFields")}</span> }} />
        </Route>
      </Route>

      <Route path="executive-report" element={<ExecutiveReport />} {...handleError} />
      <Route path="bi" element={<RevealBIPage />} {...handleError} />
      <Route path="bi2" element={<GoodDataPage />} {...handleError} />

      <Route path="test-error-page" element={<TestErrorPage />} {...handleError} />

      <Route path="/maintenance" element={<UnderConstructionPage />} />
      <Route path="/403" element={<NotAllowedPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>

    <Route path="/about-siterise-app" element={<AboutSiteRiseApp />} />
  </>
);

export const BrowserRouterApp = createBrowserRouter(RouteObjectList)
